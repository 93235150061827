// Order corresponds to order in side nav
// Not all resources appear in side nav

const resources = {
  tonnage_tickets: {
    humanReadableIdentifier: 'ticket_number',
    sort: { field: 'worked_at_date', order: 'DESC' },
  },
  hourly_tickets: {
    humanReadableIdentifier: 'ticket_number',
    sort: { field: 'worked_at_date', order: 'DESC' },
  },
  broker_driver_pay_rollups: {
    sort: { field: 'period_end_date', order: 'DESC' },
  },
  periods: {
  },
  users: {
  },
  broker_drivers: {
    postParams: [
      'num_available_trucks',
    ],
  },
  tonnage_dispatches: {
    sort: { field: '__default', order: 'ASC' },
  },
  hourly_dispatches: {
    sort: { field: '__default', order: 'ASC' },
  },
}

export default resources
