import { defaultTheme } from 'react-admin'
import createPalette from '@mui/material/styles/createPalette'
import merge from 'lodash/merge'
import { darken } from '@mui/material'

const palette = createPalette(
  merge({}, defaultTheme.palette, {
    primary: {
      main: '#F9A059',
      light: '#FEE3CA',
      // dark: '#F9A059',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#1F2E35',
      light: '#93979C',
      dark: darken('#1F2E35', 0.1),
      contrastText: '#FFFFFF',
    },
    // success: {
    //   main: '#32A852',
    // },
    // error: {
    //   main: '#F44336',
    // },
    custom: {
      gray: {
        main: '#F4F4F0',
      },
    },
  })
)

export default palette

// Color palette 1
// #1F2E35
// #93979C
// #BCCOC2
// #F4F4F0
// #F9FBF9
// #FFFFFF
// #F9A059
// #FCCEA8
// #FEE3CA
