import {
  FunctionField,
  Loading,
  TextField,
  TextInput,
} from 'react-admin'
import { useMediaQuery } from '@mui/material'
import {
  Datagrid,
  List,
  Filter,
  NumberField,
  DateField,
  CurrencyField,
} from '../../custom'
import {
  useEarliestIncompletePeriod,
  useLabel,
} from '../../../hooks'
import PeriodQuickFilter from '../../shared/period-quick-filter'
import ImageModal from '../../shared/image-modal'

const TonnageTicketsFilter = (props) => {
  const label = useLabel(props)

  return (
    <Filter {...props}>
      <TextInput label={label('ticket_number', 'find')} source='ticket_number_start' />
    </Filter>
  )
}

const TonnageTicketsList = props => {
  const { isLoading, period } = useEarliestIncompletePeriod()
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))

  if (isLoading) return <Loading />

  return (
    <List
      {...props}
      filters={<TonnageTicketsFilter />}
      filterDefaultValues={{ broker_driver_pay_rollup_period_end_date_eq: period?.end_date }}
      actions={<PeriodQuickFilter fieldName='broker_driver_pay_rollup_period_end_date' />}
    >
      {
        isSmall ?
        <Datagrid>
          <FunctionField label='ticket_image_url' render={record => (
            <ImageModal src={record.ticket_image_url} />
          )} />
          <TextField source='ticket_number' />
          <CurrencyField source='total_pay_in_dollars' />
          <DateField source='worked_at_date' />
        </Datagrid> :
        <Datagrid>
          <FunctionField label='ticket_image_url' render={record => (
            <ImageModal src={record.ticket_image_url} />
          )} />
          <TextField source='ticket_number' />
          <NumberField source='net_weight_in_tons' />
          <CurrencyField source='total_pay_in_dollars' />
          <DateField source='worked_at_date' />
          <TextField label='sender' source='sender.phone' sortBy='sender_phone'/>
        </Datagrid>
      }
    </List>
  )
}

export default TonnageTicketsList
