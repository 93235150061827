import {
  useListContext,
} from 'react-admin'
import {
  ListHeader,
  Empty,
} from '../../custom'
import {
  capitalize,
  CircularProgress,
  Grid,
  Typography,
  IconButton,
} from '@mui/material'
import PrintIcon from '@mui/icons-material/Print'
import { usePrint, useTranslateResource } from '../../../hooks'
import { Box } from '@mui/system'
import Acceptance from './acceptance'

const Header = ({ title }) => {
  return (
    <ListHeader title={
      <Typography fontSize={'1.1em'}>
        {capitalize(title)}
      </Typography>
    }/>
  )
}

const ItemGridItem = ({ record, resource, ContentComponent }) => {
  const { is_canceled } = record
  const translate = useTranslateResource(resource)

  const [
    print,
    Printable,
    willPrint,
  ] = usePrint({ title: translate('print.title') })

  return(
    <Box>
      <Box
        sx={{
          padding: 2,
          borderRadius: 2,
          border: theme => `1px solid ${theme.palette.grey[400]}`,
        }}
      >
        <Grid container spacing={3} flexDirection='column'>
          <Grid item>
            <Grid container columnSpacing={0} gap={2} flexWrap='nowrap'>
              <Grid item flexGrow={1}>
                <Acceptance
                  record={record}
                  resource={resource}
                />
              </Grid>
              {
                !is_canceled &&
                <Grid item display='flex' justifyContent='flex-end'>
                  <Box>
                    <IconButton
                      size='small'
                      color='secondary'
                      onClick={print}
                      disabled={willPrint}
                    >
                      <PrintIcon fontSize='medium' color='action' />
                    </IconButton>
                  </Box>
                </Grid>
              }
            </Grid>
          </Grid>

          <Grid item>
            <Printable renderChildren>
              <ContentComponent
                record={record}
                resource={resource}
                sx={{ opacity: is_canceled ? 0.5 : 1 }}
              />
            </Printable>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

const ItemGrid = ({ ContentComponent }) => {
  const { data, resource, defaultTitle, isLoading, total } = useListContext()

  return (
    <>
      <Header title={defaultTitle} />
      <Box
        display='grid'
        gridTemplateColumns={{
          xs: 'repeat(auto-fit, minmax(300px, 1fr))',
          sm: 'repeat(auto-fit, minmax(420px, 1fr))',
        }}
        gap={2}
        paddingX={{ xs: 1, md: 2 }}
        paddingTop={1}
        paddingBottom={2}
      >
        {
          isLoading ?
          <Grid item xs={12} display='flex' justifyContent='center'>
            <CircularProgress />
          </Grid> :
          (
            !total ?
            <Empty resource={resource} /> :
            data.map(record => {
              return (
                <ItemGridItem
                  key={record.id}
                  resource={resource}
                  record={record}
                  ContentComponent={ContentComponent}
                />
              )
            })
          )
        }
      </Box>
    </>
  )
}

export default ItemGrid
