import {
  Labeled,
} from 'react-admin'
import {
  Link,
  Typography,
} from '@mui/material'
import PlaceIcon from '@mui/icons-material/Place'
import { Box } from '@mui/system'
import AddressField from './address-field'

const LocationField = ({ label, name, href, address, isPrintView = false }) => {
  return (
    <Labeled label={label}>
      <Box>
        <Box display='inline-flex'>
          {
            !isPrintView &&
            <Link
              sx={{ marginLeft: -0.5 }}
              display='flex'
              href={href}
              target='__blank'
            >
              <PlaceIcon fontSize='small' />
            </Link>
          }
          <Typography variant='body2'>
            {name}
          </Typography>
        </Box>
        <Box>
          <AddressField record={address} />
        </Box>
      </Box>
    </Labeled>
  )
}

export default LocationField
