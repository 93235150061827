// React Admin DataProvider specs: https://marmelab.com/react-admin/DataProviders.html

import { jsonHttpClient, formDataHttpClient, getApiUrl as getUrl } from '../http-client'
import {
  getTotal,
  getSortQuery,
  getCollectionData,
  getPaginationQuery,
  getFilterQuery,
  getPostParams,
} from './util'

const getHttpClient = (data) => {
  return Object.values(data).some(v => v instanceof File) ?
    formDataHttpClient :
    jsonHttpClient
}

const log = (...args) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(...args)
  }
}

const dataProvider = () => {
  return {
    getList: async (resource, params) => {
      const query = {
        ...getFilterQuery(params, resource),
        ...getPaginationQuery(params),
        ...getSortQuery(params, resource),
      }
      const url = getUrl(resource, { query })
      const json = await jsonHttpClient.get(url)
      const total = getTotal(json)
      const collectionData = getCollectionData(json)
      const { data } = json
      const result = { data, total, collectionData }
      log('getList', resource, result)
      return result
    },

    getOne: async (resource, params) => {
      const { id, ...filter } = params
      const query = {
        ...getFilterQuery({ filter }, resource)
      }
      const url = getUrl(resource, { path: id, query })
      const json = await jsonHttpClient.get(url)
      const { data } = json
      const result = { data }
      log('getOne', resource, result)
      return result
    },

    create: async (resource, params) => {
      const url = getUrl(resource)
      const postData = getPostParams(resource, params.data)
      const httpClient = getHttpClient(postData)
      const json = await httpClient.post(url, postData)
      const { data } = json
      const result = { data }
      log('create', resource, result)
      return result
    },

    update: async (resource, params) => {
      const url = getUrl(resource, { path: params.id })
      const postData = getPostParams(resource, params.data)
      const httpClient = getHttpClient(postData)
      const json = await httpClient.patch(url, postData)
      const { data } = json
      const result = { data }
      log('update', resource, result)
      return result
    },

    delete: async (resource, params) => {
      const url = getUrl(resource, { path: params.id })
      await jsonHttpClient.delete(url)
      const result = { data: { id: params.id } }
      log('delete', resource, result)
      return result
    },

    getMany: async (resource, params) => {
      const query = {
        ...getFilterQuery({
          filter: {
            ...params.filter,
            id_in: params.ids,
          }
        }, resource),
        ...getSortQuery(params, resource),
      }
      const url = getUrl(resource, { query })
      const json = await jsonHttpClient.get(url)
      const total = getTotal(json)
      const collectionData = getCollectionData(json)
      const { data } = json
      const result = { data, total, collectionData }
      log('getMany', resource, result)
      return result
    },

    getManyReference: async (resource, params) => {
      const query = {
        ...getFilterQuery({
          filter: {
            ...params.filter,
            [`${params.target}_eq`]: params.id,
          }
        }, resource),
        ...getSortQuery(params, resource),
        ...getPaginationQuery(params),
      }
      const url = getUrl(resource, { query })
      const json = await jsonHttpClient.get(url)
      const total = getTotal(json)
      const collectionData = getCollectionData(json)
      const { data } = json
      const result = { data, total, collectionData }
      log('getManyReference', resource, result)
      return result
    },
  }
}

export default dataProvider
