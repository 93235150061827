import {
  Children,
  cloneElement
} from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import { DatagridHeaderCell, DatagridCell, } from 'react-admin'

const datagridCellDefaults = {
  sort: {},
}

const MyDatagrid = ({
  children,
  data,
}) => {
  const totalsElements = Children.map(children, c => {
    const nextProps = Object.assign({},
    c.props, {
      sx: {
        fontWeight: 'bold',
      },
      className: c.props.className,
    }, c.props.forceZero ? { forceZero: false } : null)
    return cloneElement(c, nextProps)
  })

  const headersElements = Children.map(children, c => cloneElement(c, {
    ...c.props,
    sortable: false,
    className: c.props.className,
  }))

  return (
    <Table>
      <TableHead>
        <TableRow>
          {
            headersElements.map((field, index) => (
              <DatagridHeaderCell
                {...datagridCellDefaults}
                sx={{
                  fontWeight: 'bold',
                  borderBottomWidth: 0,
                  padding: ({ spacing }) => `${spacing(1)} ${spacing(1.1)} ${spacing(2.5)}`,
                }}
                field={field}
                key={index}
              />
            ))
          }
        </TableRow>
      </TableHead>
        {
          data.map((group, groupIndex) => {
            return (
              <TableBody key={groupIndex}>
                {
                  group.data.map((row, rowIndex) => {
                    return (
                      <TableRow key={`${groupIndex}-${rowIndex}`}>
                        {
                          Children.map(children, (field, fieldIndex) => {
                            return (
                              <DatagridCell
                                key={fieldIndex}
                                sx={{
                                  '&.MuiTableCell-body': {
                                    borderWidth: 0,
                                    padding: ({ spacing }) => `${spacing(0.6)} ${spacing(1.1)}`,
                                  }
                                }}
                                field={cloneElement(field, { record: row })}
                              />
                            )
                          })
                        }
                      </TableRow>
                    )
                  })
                }
                {
                  group.subtotal != null &&
                    <TableRow key={groupIndex}>
                      {
                        totalsElements.map((field, fieldIndex) => {
                          return (
                            <DatagridCell
                              sx={{
                                '&.MuiTableCell-body': {
                                  borderWidth: 1,
                                  borderBottomWidth: 0,
                                  borderTopStyle: 'double',
                                  borderTopColor: 'inherit',
                                  padding: ({ spacing }) => `${spacing(1)} ${spacing(1.1)} ${spacing(5)}`,
                                }
                              }}
                              key={fieldIndex}
                              field={cloneElement(field, { record: group.subtotal })}
                            />
                          )
                        })
                      }
                    </TableRow>
                }
              </TableBody>
            )
          })
        }
    </Table>
  )
}

export default MyDatagrid
