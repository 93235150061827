import { useListContext } from 'react-admin'
import { useCallback, useMemo, useState, cloneElement } from 'react'
import { Drawer, Grid, useMediaQuery } from '@mui/material'
import FilterIcon from '@mui/icons-material/FilterAlt'
import FilterOffIcon from '@mui/icons-material/FilterAltOff'
import { IconButton } from '@mui/material'
import Pagination from './pagination'

const ListHeader = props => {
  const { filters, actions, title, sx, filterDefaultValues } = props
  const context = useListContext()
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('md'))

  const {
    filterValues,
    setFilters,
    resource,
  } = context

  const [drawerOpen, setDrawerOpen] = useState(false)

  const onFilterButtonClick = useCallback(() => {
    setDrawerOpen(true)
  }, [])

  const onFilterClose = useCallback(() => {
    setDrawerOpen(false)
  }, [])

  const onClearFiltersClick = useCallback(() => {
    setFilters(filterDefaultValues, null, false)
  }, [setFilters, filterDefaultValues])

  const hasAppliedFilters = useMemo(() => {
    return JSON.stringify(filterValues || {}) !== JSON.stringify(filterDefaultValues || {})
  }, [filterDefaultValues, filterValues])

  const buttonProps = {
    size: isSmall ? 'medium' : 'small',
  }

  const iconProps = {
    fontSize: isSmall ? 'medium' : 'small',
  }

  return (
    <Grid
      container
      spacing={1}
      alignItems='center'
      justifyContent='space-between'
      sx={{
        minHeight: 60,
        paddingY: 0.4,
        paddingX: 2,
        ...sx,
      }}
    >
      <Grid item>
        <Grid
          spacing={1}
          container
          alignItems='center'
          sx={{
            width: 'auto',
            flexWrap: 'nowrap',
          }}
        >
          {
            title &&
            <Grid item>
              {title}
            </Grid>
          }
          {actions}
          {
            filters &&
            <>
              <Grid item>
                <IconButton
                  {...buttonProps}
                  onClick={onFilterButtonClick}
                >
                  <FilterIcon {...iconProps}/>
                </IconButton>
              </Grid>
              {
                hasAppliedFilters &&
                <Grid item>
                  <IconButton
                    {...buttonProps}
                    color='primary'
                    onClick={onClearFiltersClick}
                  >
                    <FilterOffIcon {...iconProps}/>
                  </IconButton>
                </Grid >
              }
            </>
          }
        </Grid>
        <Drawer
          open={drawerOpen}
          onClose={onFilterClose}
          sx={{
            '& .MuiDrawer-paper': {
              padding: 2,
              width: 300,
            }
          }}
        >
          {
            filters &&
            cloneElement(filters, {
              filterValues,
              resource,
            })
          }
        </Drawer>
      </Grid>
      <Grid item>
        <Pagination />
      </Grid>
    </Grid>
  )
}

export default ListHeader
