import tonnageTicketsComponents from './tonnage-tickets'
import hourlyTicketsComponents from './hourly-tickets'
import brokerDriverPayRollupsComponents from './broker-driver-pay-rollups'

import { capitalize } from '../../util'
import camelCase from 'camelcase'
import { resourceTypes } from '../../data-model'

const componentsMap = {
  tonnageTicketsComponents,
  hourlyTicketsComponents,
  brokerDriverPayRollupsComponents,
}

const views = [
  'list',
  'create',
  'edit',
]

const mapComponents = module => {
  return views.reduce((accum, view) => {
    return {
      ...accum,
      [view]: module[capitalize(view)],
      options: {
        isMenuItem: true,
      },
    }
  }, {})
}

const adminResources = resourceTypes.map(name => {
  const module = componentsMap[`${camelCase(name)}Components`]

  return {
    name,
    ...(module ? mapComponents(module) : null),
  }
})

export default adminResources
