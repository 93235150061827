import { HttpError } from 'react-admin'
import merge from 'lodash/merge'

const deserializeErrors = (errors = {}) => {
  const { base: generalErrors, ...attributeErrors } = errors

  const finalAttributeErrors = Object.entries(attributeErrors).reduce((accum, [attr, errors]) => {
    return {
      ...accum,
      [attr]: errors[0]
    }
  }, {})

  return {
    attributeErrors: finalAttributeErrors,
    generalErrors,
  }
}

const makeRequestFn = defaultOptions => {
  return async function request(url, options = {}) {
    const mergedOptions = merge({}, defaultOptions, options)
    const { headers, ...rest } = mergedOptions

    const finalOptions = {
      headers: new Headers(headers),
      credentials: 'include',
      ...rest,
    }

    const response = await fetch(url, finalOptions)
    const { status, statusText } = response
    let body = {}

    try {
      body = await response.json()
    } catch(e) {}

    if (status < 200 || status >= 300) {
      const { errors } = body
      const deserializedErrors = deserializeErrors(errors)
      throw new HttpError(statusText, status, deserializedErrors)
    }

    return body
  }
}

export default makeRequestFn
