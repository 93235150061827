import {
  TextField,
} from 'react-admin'
import DatagridWithSubtotals from '../../custom/datagrid-with-subtotals'
import {
  useLabel,
  usePayRollupTickets,
} from '../../../hooks'
import DriverPayroll from '../../shared/driver-payroll'
import { NumberField, CurrencyField, DateField } from '../../custom'

const TicketsGrid = ({
  record: {
    tonnage_tickets: tonnageTickets,
    hourly_tickets: hourlyTickets,
  }
}) => {
  const resource = 'driver_pay_rollup_tickets'
  const label = useLabel({ resource })

  const groupedTickets = usePayRollupTickets({ tonnageTickets, hourlyTickets})

  return (
    <DatagridWithSubtotals data={groupedTickets}>
      <DateField source='date' label={label('date')} />
      <TextField source='ticket_number' label={label('ticket_number')} />
      <NumberField source='net_weight_in_tons' label={label('net_weight_in_tons')} />
      <CurrencyField source='base_rate_in_dollars' label={label('base_rate_in_dollars')} />
      <NumberField source='driver_percent_of_base_rate' label={label('driver_percent_of_base_rate')} />
      <NumberField source='driver_percent_of_surcharge_rate' label={label('driver_percent_of_surcharge_rate')} />
      <NumberField source='regular_time_in_hours' label={label('regular_time_in_hours')} forceZero />
      <CurrencyField source='regular_pay_rate_in_dollars' label={label('regular_pay_rate_in_dollars')} />
      <NumberField source='overtime_in_hours' label={label('overtime_in_hours')} forceZero />
      <CurrencyField source='overtime_pay_rate_in_dollars' label={label('overtime_pay_rate_in_dollars')} />
      <NumberField source='doubletime_in_hours' label={label('doubletime_in_hours')} forceZero />
      <CurrencyField source='doubletime_pay_rate_in_dollars' label={label('doubletime_pay_rate_in_dollars')} />
      <CurrencyField source='fuel_surcharge_in_dollars' label={label('fuel_surcharge_in_dollars')} forceZero />
      <CurrencyField source='material_surcharge_in_dollars' label={label('material_surcharge_in_dollars')} forceZero />
      <CurrencyField source='total_pay_in_dollars' label={label('total_pay_in_dollars')} forceZero />
    </DatagridWithSubtotals>
  )
}

const BrokerDriverPayroll = ({ record }) => {
  const {
    total_in_dollars,
    tenant,
    period: { end_date: date },
  } = record

  return (
    <DriverPayroll
      tenant={tenant}
      date={date}
      total={total_in_dollars}
      lineItems={<TicketsGrid record={record} />}
    />
  )
}

export default BrokerDriverPayroll
