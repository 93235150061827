import { useGetOne } from 'react-admin'

const resource = 'broker_drivers'
const id = '0' // determined from current user cookie

const useGetHauler = () => {
  const result = useGetOne(resource, { id })

  return {
    ...result,
    resource,
    id,
  }
}

export default useGetHauler
