import { Empty as RaEmpty } from 'react-admin'
import { styled } from '@mui/material/styles'

const Empty = styled(RaEmpty)({
  '& .RaEmpty-icon': {
    height: '3.2em',
    width: '3.2em',
  },
  '& .MuiTypography-root': {
    fontSize: '1.2em',
  },
})

export default Empty
