import { useCallback, useMemo, useState, useEffect } from 'react'
import { useListContext } from 'react-admin'
import { Grid, Typography, IconButton, useMediaQuery } from '@mui/material'
import NextIcon from '@mui/icons-material/ChevronRight'
import PrevIcon from '@mui/icons-material/ChevronLeft'
import { useLabel } from '../../hooks'
import { dateOnlyStringToISODate, iSODateToDateOnlyString } from '../../util/date'

const PeriodQuickFilter = ({ fieldName }) => {
  const { filterValues, setFilters, resource, isFetching } = useListContext()
  const filterKey = `${fieldName}_eq`
  const initialCurrentDate = filterValues[filterKey]
  const [currentDate, setCurrentDate] = useState(initialCurrentDate)
  const translate = useLabel({ resource })
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('md'))

  useEffect(() => {
    setCurrentDate(initialCurrentDate)
  }, [initialCurrentDate])

  const nextDate = useMemo(() => {
    return iSODateToDateOnlyString(dateOnlyStringToISODate(currentDate, 7))
  }, [currentDate])

  const prevDate = useMemo(() => {
    return iSODateToDateOnlyString(dateOnlyStringToISODate(currentDate, -7))
  }, [currentDate])

  const maxDate = iSODateToDateOnlyString(dateOnlyStringToISODate(new Date(), 7))

  const onNextClick = useCallback(() => {
    setCurrentDate(nextDate)
    setFilters({
      ...filterValues,
      [filterKey]: nextDate,
    }, null, 200)
  }, [setFilters, filterKey, filterValues, nextDate])

  const onPrevClick = useCallback(() => {
    setCurrentDate(prevDate)
    setFilters({
      ...filterValues,
      [filterKey]: prevDate,
    }, null, 200)
  }, [setFilters, filterKey, filterValues, prevDate])

  const formattedDate = useMemo(() => {
    return new Date(currentDate).toLocaleString([], {
      year:'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'UTC',
    })
  }, [currentDate])

  if (!currentDate) return null

  const buttonProps = {
    size: isSmall ? 'medium' : 'small'
  }

  const iconProps = {
    fontSize: isSmall ? 'medium' : 'small'
  }

  return (
    <Grid item>
      <Grid container alignItems='center' columnGap={0.5}>
        <Grid item order={isSmall ? 1 : 0}>
          <IconButton
            {...buttonProps}
            onClick={onPrevClick}
            disabled={isFetching}
          >
            <PrevIcon {...iconProps}/>
          </IconButton>
        </Grid>

        <Grid item marginLeft={isSmall ? 1 : 0} order={isSmall ? 0 : 1}>
          <Typography variant='subtitle2' title={translate(fieldName)}>
            {`${translate(fieldName)}: ${formattedDate}`}
          </Typography>
        </Grid>

        <Grid item order={2}>
          <IconButton
            {...buttonProps}
            onClick={onNextClick}
            disabled={isFetching || nextDate > maxDate}
          >
            <NextIcon {...iconProps}/>
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PeriodQuickFilter
