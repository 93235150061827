import { useCallback, useEffect, useState } from 'react'
import useInterval from './use-interval'
import useDidMountEffect from './use-did-mount-effect'
import { debounce } from '@mui/material'

const isVisible = () => document.visibilityState === 'visible'
const visibilityDebounceMs = 1000

const usePolling = (cb, ms) => {
  const [isDocumentVisible, setIsDocumentVisible] = useState(isVisible())
  const onVisibilityChange = useCallback(() => setIsDocumentVisible(isVisible()), [])
  // eslint-disable-next-line
  const debouncedOnVisibilityChange = useCallback(debounce(onVisibilityChange, visibilityDebounceMs), [])

  useInterval(cb, ms, !isDocumentVisible)

  useEffect(() => {
    const ev = 'visibilitychange'
    const fn = () => {
      if (isVisible()) {
        debouncedOnVisibilityChange()
      } else {
        debouncedOnVisibilityChange.clear()
        setIsDocumentVisible(false)
      }
    }
    window.addEventListener(ev, fn)
    return () => window.removeEventListener(ev, fn)
  }, [debouncedOnVisibilityChange, onVisibilityChange])

  useDidMountEffect(() => {
    if (isDocumentVisible) cb()
  }, [isDocumentVisible])
}

export default usePolling
