import {
  NumberField,
  TextField,
  Labeled,
} from 'react-admin'
import {
  DateTimeField,
  CurrencyField,
} from '../custom'
import {
  Grid, Typography,
} from '@mui/material'
import { useLabel } from '../../hooks'
import { Box } from '@mui/system'
import ItemGrid from '../shared/dispatches/item-grid'
import LocationField from '../shared/dispatches/location-field'
import { getMaterialAndGrade } from '../shared/dispatches/util'

const Content = ({ record, resource, sx, isPrintView = false }) => {
  const label = useLabel({ resource })

  return (
    <Box sx={{
      ...sx,
      padding: isPrintView ? [4, 2] : 0,
    }}>
      <Grid
        container
        columnSpacing={2}
        rowSpacing={isPrintView ? 4 : 2.4}
      >
        <Grid item xs={6}>
          <Labeled label={label('order.load_at')}>
            <DateTimeField record={record} source='order.load_at' />
          </Labeled>
        </Grid>
        <Grid item xs={6} >
          <Labeled label={label('order.order_number')}>
            <TextField record={record} source='order.order_number'/>
          </Labeled>
        </Grid>
        <Grid item xs={6}>
          <LocationField
            label={label('pickup_site.name')}
            name={record.pickup_site.name}
            href={record.pickup_site.google_maps_pin_link}
            address={record.pickup_site}
            isPrintView={isPrintView}
          />
        </Grid>
        <Grid item xs={6}>
          <LocationField
            label={label('dropoff_site.name')}
            name={record.dropoff_site.name}
            href={record.dropoff_site.google_maps_pin_link}
            address={record.dropoff_site}
            isPrintView={isPrintView}
          />
        </Grid>
        <Grid item xs={6}>
          <Labeled label={label('material.name')}>
            <Typography variant='body2'>
              {getMaterialAndGrade(record.material.name, record.order.material_grade)}
            </Typography>
          </Labeled>
        </Grid>
        <Grid item xs={6}>
          <Labeled label={label('recipient.name')}>
            <TextField record={record} source='recipient.name'/>
          </Labeled>
        </Grid>
        <Grid item xs={6}>
          <Labeled label={label('num_trucks')}>
            <NumberField record={record} source='num_trucks' />
          </Labeled>
        </Grid>
        <Grid item xs={6}>
          <Labeled label={label('broker_rate_in_dollars')}>
            <CurrencyField record={record} source='broker_rate_in_dollars' />
          </Labeled>
        </Grid>
        {
          record.notes &&
          <Grid item xs={12}>
            <Labeled label={label('notes')}>
              <TextField component='pre' record={record} source='notes' sx={{ whiteSpace: 'pre-line' }} />
            </Labeled>
          </Grid>
        }
      </Grid>
    </Box>
  )
}

const TonnageDispatches = () => <ItemGrid ContentComponent={Content} />

export default TonnageDispatches
