import { useRef, Children, useMemo, cloneElement, useState, useCallback, useEffect } from 'react'
import { useReactToPrint } from 'react-to-print'
import { Box } from '@mui/material'

const usePrint = ({ title, landscape = false } = {}) => {
  const [willPrint, setWillPrint] = useState(false)
  const ref = useRef()

  const onAfterPrint = useCallback(() => {
    setWillPrint(false)
  }, [])

  const print = useCallback(() => {
    setWillPrint(true)
  }, [])

  const content = useCallback(() => {
    return ref.current
  }, [])

  const pageStyle = useMemo(() => {
    return `
      @media print {
        body {
          height: 100%;
        }
        @page {
          size: ${landscape ? 'landscape' : 'portrait'};
        }
      }
    `
  }, [landscape])

  const _print = useReactToPrint({
    content,
    onAfterPrint,
    pageStyle,
    removeAfterPrint: true,
    documentTitle: title,
  })

  useEffect(() => {
    if (willPrint) _print()
  }, [willPrint, _print])

  const Printable = useMemo(() => ({ children, renderChildren, ...rest }) => {
    return (
      <>
        {
          renderChildren &&
          cloneElement(children, rest)
        }
        {
          willPrint &&
          <Box style={{display: 'none'}}>
            <Box ref={ref}>
              {Children.map(children, (child, idx) => {
                return (
                  <Box key={idx}>
                    <Box sx={{ pageBreakBefore: 'always' }} />
                    {cloneElement(child, { ...rest, isPrintView: true })}
                  </Box>
                )
              })}
            </Box>
          </Box>
        }
      </>
    )
  }, [willPrint])

  return [
    print,
    Printable,
    willPrint,
  ]
}

export default usePrint
