const OFFSET_MULTIPLIER = 60 * 1000

const getOffsetMS = date => {
  return date.getTimezoneOffset() * OFFSET_MULTIPLIER
}

const iSODateToDateOnlyString = iSODate => {
  const date = new Date(iSODate)
  return new Date(date.getTime() - getOffsetMS(date)).toISOString().split('T')[0]
}

const dateOnlyStringToJSDate = dateOnlyStr => {
  const date = new Date(dateOnlyStr)
  return new Date(date.getTime() + getOffsetMS(date))
}

const dateOnlyStringToISODate = (dateOnlyStr, daysAdjustment = 0) => {
  const date = dateOnlyStringToJSDate(dateOnlyStr)
  return new Date(date.setDate(date.getDate() + daysAdjustment)).toISOString()
}

export {
  iSODateToDateOnlyString,
  dateOnlyStringToISODate,
  dateOnlyStringToJSDate,
}
