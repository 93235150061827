import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import ErrorIcon from '@mui/icons-material/Error'

const materialGradeDisplayNames = {
  commercial: 'COMM',
  state: 'STATE',
}

const getMaterialAndGrade = (materialName, materialGradeKey) => {
  return [
    materialName,
    materialGradeDisplayNames[materialGradeKey] == null ? '' : ` (${materialGradeDisplayNames[materialGradeKey]})`,
  ].join('')
}

const acceptanceStatusTypes = {
  accepted: {
    iconColor: 'success',
    buttonColor: 'success',
    buttonText: 'form.acceptButton',
    statusMessage: 'form.acceptedStatusMessage',
    changeAction: 'form.changeToDeclinedAction',
    Icon: CheckIcon,
  },
  declined: {
    iconColor: 'secondary',
    buttonColor: 'error',
    buttonText: 'form.declineButton',
    statusMessage: 'form.declinedStatusMessage',
    changeAction: 'form.changeToAcceptedAction',
    Icon: CloseIcon,
  },
}

const cancelConfirmStatusTypes = {
  true: {
    color: 'error',
    statusMessage: 'form.cancelConfirmedStatusMessage',
    Icon: ErrorIcon,
  },
  false: {
    color: 'error',
    buttonText: 'form.confirmCancelButton',
    statusMessage: 'form.awaitingCancelConfirmationStatusMessage',
    Icon: ErrorIcon,
  },
}
export {
  acceptanceStatusTypes,
  cancelConfirmStatusTypes,
  getMaterialAndGrade,
}
