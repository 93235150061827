import { Typography, Box, Grid } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  SimpleForm,
  SaveButton,
  required,
  EditContextProvider,
  useUpdate,
  useTranslate,
} from 'react-admin'
import { useGetHauler, useNotifyResponse } from '../../hooks'
import { capitalize } from '../../util'
import { NumberInput } from '../custom'

const timeZone = 'US/Eastern'
const hourOfDayCutoff = 15 // 3pm

const dateFormat = {
  year:'numeric',
  month: 'short',
  day: '2-digit',
  weekday: 'short',
}

const useWorkdayCutoff = () => {
  const getDate = useCallback(() => {
    const now = new Date()
    const nowInTimezone = new Date(now.toLocaleString('en-US', { timeZone }))

    if (nowInTimezone.getHours() < hourOfDayCutoff) {
      return now
    } else {
      return new Date(now.setDate(now.getDate() + 1))
    }
  }, [])

  const [date, setDate] = useState(getDate)

  const formattedDate = useMemo(() => {
    return new Intl.DateTimeFormat('en-US', dateFormat).format(date)
  }, [date])

  useEffect(() => {
    const interval = setInterval(() => setDate(getDate), 1000)
    return () => clearInterval(interval)
  }, [getDate])

  return formattedDate
}

const TargetDate = ({ sx }) => {
  const formattedDate = useWorkdayCutoff()
  return (
    <Typography sx={sx}>
      {formattedDate}
    </Typography>
  )
}

const AvailableTrucks = () => {
  const translate = useTranslate()
  const { isLoading, data: hauler, resource, id } = useGetHauler()
  const resourceName = useMemo(() => {
    return capitalize(translate(`resources.${resource}.fields.num_available_trucks`))
  }, [resource, translate])

  const [update, { isLoading: isSubmitting }] = useUpdate()
  const { notifySuccess, notifyFailure } = useNotifyResponse({ resourceName })

  const onSubmit = useCallback(async (data) => {
    try {
      await update('broker_drivers', {
        id,
        data,
      }, {
        returnPromise: true
      })
      notifySuccess('updated')
    } catch (error) {
      notifyFailure(error)
      if (error.body.attributeErrors) {
        return error.body.attributeErrors
      }
    }
  }, [update, notifyFailure, notifySuccess, id])

  const infoText = useMemo(() => {
    const date = new Date(new Date().setHours(hourOfDayCutoff))
    const time = new Intl.DateTimeFormat('en-US', { hour: 'numeric' }).format(date).replace(/\s/, '').toLocaleLowerCase()
    return translate(`resources.${resource}.info.num_available_trucks`, { time, timeZone })
  }, [translate, resource])

  return (
    <Box
      display='grid'
      gap={2}
      padding={2}
      gridTemplateColumns={'repeat(auto-fill, minmax(350px, auto))'}
    >
      <Box>
        <Typography marginBottom={1} fontSize='1.1em'>
          {resourceName}
        </Typography>
        <Typography marginBottom={0} variant='body2'>
          {infoText}
        </Typography>
      </Box>
      <Box>
        <EditContextProvider
          value={{
            record: hauler,
            isLoading,
            save: onSubmit,
            saving: isSubmitting,
          }}
        >
          <SimpleForm
            sx={{
              padding: '0 !important',
            }}
            toolbar={null}
          >
            <TargetDate sx={{ fontWeight: 'bold', marginBottom: 1.6 }}/>
            <Grid container spacing={2} wrap='nowrap'>
              <Grid item width={170}>
                <NumberInput
                  source='num_available_trucks'
                  label={false}
                  min={0}
                  max={100}
                  variant='outlined'
                  validate={required()}
                  sx={{
                    margin: 0,
                    padding:0,
                  }}
                />
              </Grid>
              <Grid item>
                <Box sx={{ marginTop: 0.5 }}>
                  <SaveButton
                    sx={{
                      position: 'relative',
                      '& .MuiCircularProgress-root': {
                        margin: 0,
                        position: 'absolute',
                        color: 'primary.main',
                      }
                    }}
                    size='small'
                    icon={<></>}
                  />
                </Box>
              </Grid>
            </Grid>
          </SimpleForm>
        </EditContextProvider>
      </Box>
    </Box>
  )
}

export default AvailableTrucks
