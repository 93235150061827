import { Card } from '@mui/material'
import {
  ListContextProvider,
  Title,
  useListController,
  useResourceContext,
} from 'react-admin'
import ListHeader from './list-header'
import { sorts } from '../../data-model'

const List = ({ children, filterDefaultValues, actions, filters }) => {
  const resource = useResourceContext()
  const sort = sorts[resource]
  const context = useListController({
    perPage: 25,
    sort,
    filterDefaultValues,
  })
  const { defaultTitle } = context

  return (
    <ListContextProvider value={context}>
      <div>
        <Title title={defaultTitle}/>
        <Card
          sx={{
            marginBottom: 1,
            marginTop: 1,
            position: 'sticky',
            top: 48,
            zIndex: 3,
          }}
        >
          <ListHeader
            actions={actions}
            filters={filters}
            filterDefaultValues={filterDefaultValues}
          />
        </Card>
        <Card>
          {children}
        </Card>
      </div>
    </ListContextProvider>
  )
}

export default List
