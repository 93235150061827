import { useState, useCallback } from 'react'
import { Dialog } from '@mui/material'
import ImageIcon from '@mui/icons-material/FilePresent'
import MissingIcon from '@mui/icons-material/Block'
import { IconButton } from '@mui/material'

const buttonProps = {
  size: 'small'
}

const ImageModal = ({ src }) => {
  const [showModal, setShowModal] = useState(false)

  const onClick = useCallback(() => {
    setShowModal(true)
  }, [])

  const onModalClose = useCallback(() => {
    setShowModal(false)
  }, [])

  return (
    <>
      {
        src ?
        <IconButton
          {...buttonProps}
          focusRipple={false}
          onClick={onClick}
        >
          <ImageIcon fontSize='medium'/>
        </IconButton> :
        <IconButton
          {...buttonProps}
          disabled
        >
          <MissingIcon />
        </IconButton>
      }
      <Dialog
        maxWidth='lg'
        open={showModal}
        onClose={onModalClose}
        sx={{
          '& .MuiDialog-paper': {
            marginX: 1,
            maxHeight: ({ spacing }) => `calc(100% - ${spacing(4)})`,
          },
        }}
      >
        <img
          style={{ width: '100%' }}
          src={src}
          alt=''
        />
      </Dialog>
    </>
  )
}

export default ImageModal
