import {
  useTranslate,
} from 'react-admin'
import {
  Grid,
  Typography,
  Box,
} from '@mui/material'
import {
  CurrencyField,
} from '../custom'

const dateFieldOptions = {
  year:'numeric',
  month: '2-digit',
  day: '2-digit',
  timeZone: 'UTC',
}

const TenantField = ({ record }) => {
  return (
    <>
      <Typography variant='h6'>
        {record.name}
      </Typography>
      <Typography>
        {record.address_street_1}
      </Typography>
      <Typography>
        {`${record.address_city}, ${record.address_united_state_abbreviation} ${record.address_zip}`}
      </Typography>
    </>
  )
}

const DriverPayroll = ({
  total,
  date,
  lineItems,
  tenant,
}) => {

  const translate = useTranslate()

  return (
    <Box>
      <Grid container sx={{ marginBottom: 2 }}>
        <Grid item xs={6}>
          <TenantField record={tenant} />
        </Grid>
        <Grid item xs={6}>
          <Typography variant='h6' align='right'>
            {new Date(date).toLocaleString([], dateFieldOptions)}
          </Typography>
        </Grid>
      </Grid>

      <Grid container sx={{ marginBottom: 2 }}>
        {lineItems}
      </Grid>

      <Grid container justifyContent='flex-end'>
        <Typography
          align='right'
          sx={{
            fontWeight: 'bold',
          }}
        >
          {`${translate('payroll.total')}:`}
          &nbsp;
        </Typography>
        <CurrencyField
          record={{ total }}
          source='total'
          sx={{
            fontSize: '1rem',
            fontWeight: 'bold',
          }}
        />
      </Grid>
    </Box>
  )
}

export default DriverPayroll
