import {
  DateField as RaDateField
} from 'react-admin'

const options = {
  year:'numeric',
  month: '2-digit',
  day: '2-digit',
  timeZone: 'UTC',
}

const DateField = props => {
  return (
    <RaDateField
      options={options}
      {...props}
    />
  )
}

export default DateField
