import {
  NumberField,
} from 'react-admin'
import { useMediaQuery } from '@mui/material'
import {
  Datagrid,
  List,
  DateField,
  CurrencyField,
} from '../../custom'
import Payroll from './payroll'
import GridExpand from '../../shared/grid-expand'

const Expand = (props) => (
  <GridExpand {...props}>
    <Payroll/>
  </GridExpand>
)

const TonnageTicketsList = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))

  return (
    <List
      {...props}
    >
      {
        isSmall ?
        <Datagrid>
          <DateField source='period.end_date' sortBy='period_end_date'/>
          <NumberField source='total_line_item_quantity' />
          <CurrencyField source='total_in_dollars' />
        </Datagrid> :
        <Datagrid expand={<Expand />}>
          <DateField source='period.end_date' sortBy='period_end_date'/>
          <NumberField source='total_line_item_quantity' />
          <CurrencyField source='tonnage_total_in_dollars' />
          <CurrencyField source='hourly_total_in_dollars' />
          <CurrencyField source='total_in_dollars' />
        </Datagrid>
      }
    </List>
  )
}

export default TonnageTicketsList
