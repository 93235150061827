const resources = {
  tonnage_tickets: {
    name: 'tonnage ticket |||| tonnage tickets',
    contextualType: 'tonnage',
    fields: {
      ticket_number: 'ticket #',
      worked_at_date: 'date',
      net_weight_in_tons: 'tons',
      snapshot_mode: 'archived?',
      broker_driver_pay_rollup_period_end_date: 'week end',
      total_pay_in_dollars: 'earned',
      ticket_image_url: 'image',
      sender: 'sender',
    },
  },
  hourly_tickets: {
    name: 'hourly ticket |||| hourly tickets',
    contextualType: 'hourly',
    fields: {
      ticket_number: 'ticket #',
      worked_at_date: 'date',
      regular_time_in_hours: 'reg hrs',
      overtime_in_hours: 'OT hours',
      doubletime_in_hours: 'DT hours',
      snapshot_mode: 'archived?',
      broker_driver_pay_rollup_period_end_date: 'week end',
      total_pay_in_dollars: 'earned',
      ticket_image_url: 'image',
      sender: 'sender',
    },
  },
  broker_driver_pay_rollups: {
    name: 'earning |||| earnings',
    fields: {
      driver: {
        name: 'driver',
      },
      period: {
        end_date: 'week end',
      },
      tonnage_total_in_dollars: 'tonnage',
      hourly_total_in_dollars: 'hourly',
      total_in_dollars: 'total',
      total_line_item_quantity: 'tickets',
      // hourly_regular_total_in_dollars: 'reg hr',
      // hourly_overtime_total_in_dollars: 'OT hr',
      // hourly_doubletime_total_in_dollars: 'DT hr',
    }
  },
  driver_pay_rollup_tickets: {
    name: 'ticket |||| tickets',
    fields: {
      ticket_number: 'ticket #',
      net_weight_in_tons: 'tons',
      date: 'date',
      base_rate_in_dollars: 'ton rate',
      fuel_surcharge_in_dollars: 'fuel',
      material_surcharge_in_dollars: 'material',
      total_pay_in_dollars: 'total',
      driver_percent_of_base_rate: '% base',
      driver_percent_of_surcharge_rate: '% sur',
      regular_time_in_hours: 'RG hr',
      overtime_in_hours: 'OT hr',
      doubletime_in_hours: 'DT hr',
      regular_pay_rate_in_dollars: 'RG rate',
      overtime_pay_rate_in_dollars: 'OT rate',
      doubletime_pay_rate_in_dollars: 'DT rate',
    },
  },
  periods: {
    name: 'period |||| periods',
  },
  users: {
    name: 'account',
    fields: {
      email: 'email',
      new_password: 'new password',
      old_password: 'old password',
    },
  },
  broker_drivers: {
    fields: {
      name: 'name',
      num_available_trucks: 'number of available trucks',
    },
    info: {
      num_available_trucks: 'Enter number of trucks available to Haulz Logistics for the date shown. The number will remain until you change it. Date updates to the following day at %{time} %{timeZone}.',
    },
  },
  tonnage_dispatches: {
    name: 'tonnage order |||| tonnage orders',
    fields: {
      num_trucks: '# Trucks',
      broker_rate_in_dollars: 'rate',
      notes: 'notes',
      order: {
        order_number: 'order #',
        load_at: 'load at',
      },
      material: {
        name: 'material',
      },
      dropoff_site: {
        name: 'dropoff',
      },
      pickup_site: {
        name: 'pickup',
      },
      recipient: {
        name: 'recipient',
      },
    },
    print: {
      title: 'Haulz Logistics - Tonnage',
    },
  },
  hourly_dispatches: {
    name: 'hourly order |||| hourly orders',
    fields: {
      broker_rates: 'rates (reg / ot / dt)',
      num_trucks: '# Trucks',
      notes: 'notes',
      order: {
        order_number: 'order #',
        arrive_at: 'arrive at',
      },
      location: {
        name: 'location',
      },
      hourly_work_type: {
        name: 'work type',
      },
    },
    print: {
      title: 'Haulz Logistics - Hourly',
    },
  },

  dispatches: {
    name: 'order |||| orders',
    form: {
      acceptButton: 'Accept',
      declineButton: 'Decline',
      acceptedStatusMessage: 'You have accepted this order.',
      declinedStatusMessage: 'You have declined this order.',
      changeToAcceptedAction: 'Change to accept',
      changeToDeclinedAction: 'Change to decline',

      cancelConfirmedStatusMessage: 'This order has been canceled. You have confirmed.',
      confirmCancelButton: 'Click to confirm',
      awaitingCancelConfirmationStatusMessage: 'This order has been canceled.',
    },
  },
}

export default {
  resources,
}
