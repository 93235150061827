import { useMemo, useCallback } from 'react'
import {
  useUpdate,
} from 'react-admin'
import {
  capitalize,
  Grid,
  Link,
  Typography,
  Button,
} from '@mui/material'
import { useNotifyResponse, useTranslateResource } from '../../../hooks'
import { acceptanceStatusTypes, cancelConfirmStatusTypes } from './util'

const Acceptance = ({ record, resource }) => {
  const { id, hauler_acceptance_status } = record
  const translateDispatch = useTranslateResource('dispatches')
  const translate = useTranslateResource(resource)

  const resourceName = useMemo(() => {
    return capitalize(translate('name', { smart_count: 1 }))
  }, [translate])

  const [update, { isLoading: isSubmittingAcceptanceStatus }] = useUpdate()
  const { notifySuccess, notifyFailure } = useNotifyResponse({ resourceName })

  const updateAcceptanceStatus = useCallback(async status => {
    try {
      await update(resource, {
        id,
        data: { hauler_acceptance_status: status },
      }, {
        returnPromise: true,
      })
      notifySuccess(status)
    } catch (error) {
      notifyFailure(error)
      if (error.body.attributeErrors) {
        return error.body.attributeErrors
      }
    }
  }, [update, notifyFailure, notifySuccess, resource, id])

  const {
    Icon,
    iconColor,
    statusMessage,
    changeAction,
  } = acceptanceStatusTypes[hauler_acceptance_status] || {}

  if (hauler_acceptance_status) {
    return (
      <Grid container columnSpacing={0.8} alignItems='center'>
        <Grid item display='flex'>
          <Icon fontSize='small' color={iconColor} />
        </Grid>
        <Grid item>
          <Typography variant='body2'>
            {translateDispatch(statusMessage)}
          </Typography>
        </Grid>
        <Grid item>
          <Link
            variant='body2'
            component='button'
            color='secondary'
            sx={{ verticalAlign: 'inherit' }}
            onClick={updateAcceptanceStatus.bind(null, hauler_acceptance_status === 'accepted' ? 'declined' : 'accepted')}
          >
            {translateDispatch(changeAction)}
          </Link>
        </Grid>
      </Grid>
    )
  } else {
    return (
      <Grid container spacing={2}>
        {
          Object.keys(acceptanceStatusTypes).map(key => {
            return (
              <Grid item key={key}>
                <Button
                  variant='contained'
                  color={acceptanceStatusTypes[key].buttonColor}
                  size='small'
                  disabled={isSubmittingAcceptanceStatus}
                  onClick={updateAcceptanceStatus.bind(null, key)}
                >
                  {translateDispatch(acceptanceStatusTypes[key].buttonText)}
                </Button>
              </Grid>
            )
          })
        }
      </Grid>
    )
  }
}

const CancelConfirmation = ({ record, resource }) => {
  const { id, is_cancel_confirmed } = record
  const translateDispatch = useTranslateResource('dispatches')
  const translate = useTranslateResource(resource)

  const resourceName = useMemo(() => {
    return capitalize(translate('name', { smart_count: 1 }))
  }, [translate])

  const [update, { isLoading: isSubmittingCancelConfirm }] = useUpdate()
  const { notifySuccess, notifyFailure } = useNotifyResponse({ resourceName })

  const updateCancelConfirm = useCallback(async () => {
    try {
      await update(resource, {
        id,
        data: { is_cancel_confirmed: true },
      }, {
        returnPromise: true,
      })
      notifySuccess('cancelConfirmed')
    } catch (error) {
      notifyFailure(error)
      if (error.body.attributeErrors) {
        return error.body.attributeErrors
      }
    }
  }, [update, notifyFailure, notifySuccess, resource, id])

  const {
    Icon,
    color,
    statusMessage,
    buttonText,
  } = cancelConfirmStatusTypes[is_cancel_confirmed] || {}

  return (
    <Grid container columnSpacing={0.8} alignItems='center'>
      <Grid item display='flex'>
        <Icon fontSize='small' color={color} />
      </Grid>
      <Grid item>
        <Typography variant='body2'>
          {translateDispatch(statusMessage)}
        </Typography>
      </Grid>
      {
        buttonText &&
        <Grid item>
          <Button
            variant='contained'
            color='secondary'
            size='small'
            disabled={isSubmittingCancelConfirm}
            onClick={updateCancelConfirm}
          >
            {translateDispatch(buttonText)}
          </Button>
        </Grid>
      }
    </Grid>
  )
}

const Component = props => {
  const { record: { is_canceled } }= props
  return (
    is_canceled ?
      <CancelConfirmation {...props} /> :
      <Acceptance {...props} />
  )
}

export default Component
