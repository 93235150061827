import {
  cloneElement,
} from 'react'
import {
  Grid,
  Card,
  CardContent,
  IconButton,
  Typography,
} from '@mui/material'
import {
  useGetOne,
  useTranslate,
} from 'react-admin'
import PrintIcon from '@mui/icons-material/Print'
import usePrint from '../../hooks/use-print'

const Loading = () => {
  const translate = useTranslate()

  return (
    <Typography
      variant='body1'
    >
      {`${translate('ra.page.loading')}...`}
    </Typography>
  )
}

const GridExpand = ({ children, resource, record, payload = {} }) => {
  const { data: detail, isFetching } = useGetOne(resource,
    {
      ...payload,
      id: record.id,
    }
  )

  const [
    print,
    Printable,
    willPrint,
  ] = usePrint()

  return (
    <Grid
      container
      spacing={1}
      flexWrap='nowrap'
      sx={{
        marginRight: -1.75,
        marginLeft: -1.75,
        paddingTop: 1,
        paddingBottom: 1,
      }}
    >
      <Grid item>
        <IconButton
          size='small'
          color='secondary'
          onClick={print}
          disabled={willPrint}
        >
          <PrintIcon color='action'/>
        </IconButton>
      </Grid>
      <Grid item flexGrow='1'>
        <Card>
          <CardContent>
            {
              isFetching ?
              <Loading /> :
              <Printable renderChildren>
                {cloneElement(children, { record: detail })}
              </Printable>
            }
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default GridExpand
