import { NumberInput as RaNumberInput } from 'react-admin'

const onWheel = e => {
  if (e.target === document.activeElement) {
    e.target.blur()
    e.stopPropagation()
    window.requestAnimationFrame(() => {
      e.target.focus({ preventScroll: true})
    })
  }
}

const NumberInput = ({ label, sx, ...rest }) => {

  return (
    <RaNumberInput
      {...rest}
      onWheel={onWheel}
      label={label}
      sx={{
        ...sx,
        '& .MuiOutlinedInput-notchedOutline': {
          '& legend': {
            width: label ? undefined : 0,
          },
        },
      }}
    />
  )
}

export default NumberInput
