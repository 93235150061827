import { cloneElement, Children } from 'react'
import { FilterForm as RaFilter } from 'react-admin'

const Filter = ({ filters, children, ...rest }) => {
  return (
    <RaFilter
      {...rest}
      filters={Children.map(children, child => cloneElement(child, {
        alwaysOn: true,
        fullWidth: true,
        ...child.props,
      }))}
    />
  )
}

export default Filter
