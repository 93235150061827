
import {
  Menu as RaMenu,
  useResourceDefinitions,
  useTranslate
} from 'react-admin'
import ListIcon from '@mui/icons-material/List'

const sx = {
  textTransform: 'capitalize',
  '& .RaMenuItemLink-icon': {
    minWidth: 0,
    marginRight: 1,
  }
}

const Menu = () => {
  const resources = useResourceDefinitions()
  const translate = useTranslate()

  return (
    <RaMenu
    >
      <RaMenu.DashboardItem sx={sx} />
      {Object.keys(resources).map(key => (
        resources[key].hasList &&
        <RaMenu.Item
          key={key}
          to={`/${resources[key].name}`}
          primaryText={translate(`resources.${resources[key].name}.name`, { smart_count: 2 })}
          leftIcon={resources[key].icon || <ListIcon />}
          sx={sx}
        />
      ))}
    </RaMenu>
  )
}

export default Menu
