import { useCallback } from 'react'
import { ListContextProvider, Title, useListController } from 'react-admin'
import { Card, Grid, Box, CircularProgress } from '@mui/material'
import AvailableTrucks from './available-trucks'
import TonnageDispatches from './tonnage-dispatches'
import HourlyDispatches from './hourly-dispatches'
import { sorts } from '../../data-model'
import { Empty } from '../custom'
import { usePolling } from '../../hooks'

const pollingMs = 30000

const gridItemStyle = {
  flexShrink: 1,
  flexGrow: 1,
  flexBasis: '50%',
}

const baseControllerProps = {
  perPage: 25,
  disableSyncWithLocation: true,
}

const tonnageDispatchListControllerProps = {
  ...baseControllerProps,
  resource: 'tonnage_dispatches',
  sort: sorts['tonnage_dispatches'],
}

const hourlyDispatchListControllerProps = {
  ...baseControllerProps,
  resource: 'hourly_dispatches',
  sort: sorts['hourly_dispatches'],
}

const Placeholder = ({ children }) => (
  <Grid item xs={12}>
    <Card sx={{
      height: 300,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      {children}
    </Card>
  </Grid>
)

const Dashboard =() => {
  const tonnageDispatchContext = useListController(tonnageDispatchListControllerProps)
  const hourlyDispatchContext = useListController(hourlyDispatchListControllerProps)

  const { isLoading: isLoadingTonnage, total: tonnageTotal, refetch: refetchTonnage } = tonnageDispatchContext
  const { isLoading: isLoadinghourly, total: hourlyTotal, refetch: refetchHourly } = hourlyDispatchContext

  const isLoadingDispatches = isLoadingTonnage || isLoadinghourly
  const hasAnyDispatches = [tonnageTotal, hourlyTotal].some(Boolean)

  const refetch = useCallback(() => {
    refetchTonnage()
    refetchHourly()
  }, [refetchTonnage, refetchHourly])

  usePolling(refetch, pollingMs)

  return (
    <Box sx={{ marginTop: 1 }}>
      <Title title='dashboard' />
      <Grid container columnSpacing={2} rowSpacing={4}>
        <Grid item xs={12}>
          <Card>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} sx={gridItemStyle}>
                <AvailableTrucks />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {
          isLoadingDispatches ?
          <Placeholder>
            <CircularProgress />
          </Placeholder>: (
            !hasAnyDispatches ?
            <Placeholder>
              <Empty resource='dispatches' />
            </Placeholder> :
            <>
              {
                tonnageTotal > 0 &&
                <Grid item sx={gridItemStyle}>
                  <Card>
                    <ListContextProvider value={tonnageDispatchContext}>
                      <TonnageDispatches />
                    </ListContextProvider>
                  </Card>
                </Grid>
              }
              {
                hourlyTotal > 0 &&
                <Grid item sx={gridItemStyle}>
                  <Card>
                    <ListContextProvider value={hourlyDispatchContext}>
                      <HourlyDispatches />
                    </ListContextProvider>
                  </Card>
                </Grid>
              }
            </>
          )
        }
      </Grid>
    </Box>
  )
}

export default Dashboard
