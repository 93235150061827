import { useMemo } from 'react'
import { useGetList } from 'react-admin'

const useEarliestIncompletePeriod = () => {
  const resource = 'periods'

  const { data, isLoading } = useGetList(resource, {
    pagination: {
      page: 1,
      perPage: 1
    },
    filter: {
      is_completed_true: false,
    },
    sort: {
      field: 'end_date',
      order: 'ASC',
    }
  })

  const period = useMemo(() => {
    return data?.find(p => p.is_completed === false)
  }, [data])

  return {
    isLoading,
    period,
  }
}

export default useEarliestIncompletePeriod
