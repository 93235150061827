import { useMemo } from 'react'
import {
  NumberField,
  TextField,
  Labeled,
} from 'react-admin'
import {
  Grid,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import {
  DateTimeField,
} from '../custom'
import { currencyFieldOptions } from '../custom/currency-field'
import { useLabel } from '../../hooks'
import ItemGrid from '../shared/dispatches/item-grid'
import LocationField from '../shared/dispatches/location-field'

const Content = ({ record, resource, sx, isPrintView = false }) => {
  const label = useLabel({ resource })

  const rates = useMemo(() => (
    [
      record.broker_regular_rate_in_dollars,
      record.broker_overtime_rate_in_dollars,
      record.broker_doubletime_rate_in_dollars,
    ].map(rate => Intl.NumberFormat('en-US', currencyFieldOptions).format(rate)).join(' / ')
  ), [record])

  return (
    <Box sx={{
      ...sx,
      padding: isPrintView ? [4, 2] : 0,
    }}>
      <Grid
        container
        columnSpacing={2}
        rowSpacing={isPrintView ? 4 : 2.4}
      >
        <Grid item xs={6}>
          <Labeled label={label('order.arrive_at')}>
            <DateTimeField record={record} source='order.arrive_at' />
          </Labeled>
        </Grid>
        <Grid item xs={6} >
          <Labeled label={label('order.order_number')}>
            <TextField record={record} source='order.order_number'/>
          </Labeled>
        </Grid>
        <Grid item xs={6}>
          <LocationField
            label={label('location.name')}
            name={record.location.name}
            href={record.location.google_maps_pin_link}
            address={record.location}
            isPrintView={isPrintView}
          />
        </Grid>
        <Grid item xs={6}>
          <Labeled label={label('hourly_work_type.name')}>
            <TextField record={record} source='hourly_work_type.name'/>
          </Labeled>
        </Grid>
        <Grid item xs={6}>
          <Labeled label={label('num_trucks')}>
            <NumberField record={record} source='num_trucks' />
          </Labeled>
        </Grid>
        <Grid item xs={6}>
          <Labeled label={label('broker_rates')}>
            <Typography variant='body2'>
              {rates}
            </Typography>
          </Labeled>
        </Grid>
        {
          record.notes &&
          <Grid item xs={12}>
            <Labeled label={label('notes')}>
              <TextField component='pre' record={record} source='notes' sx={{ whiteSpace: 'pre-line' }} />
            </Labeled>
          </Grid>
        }
      </Grid>
    </Box>
  )
}

const HourlyDispatches = () => <ItemGrid ContentComponent={Content} />

export default HourlyDispatches
