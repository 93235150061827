import { useMemo } from 'react'
import { Typography } from '@mui/material'

const AddressField = ({ record }) => {
  const formattedAddress = useMemo(() => {
    const { address_street_1, address_street_2, address_united_state_abbreviation, address_city, address_zip } = record
    return [
      address_street_1 ? `${address_street_1},` : null,
      address_street_2 ? `${address_street_2},` : null,
      address_city ? `${address_city},` : null,
      address_united_state_abbreviation,
      address_zip,
    ].filter(Boolean).join(' ')
  }, [record])

  return (
    <Typography
      variant='body2'
      fontSize='0.8em'
    >
      {formattedAddress}
    </Typography>
  )
}

export default AddressField
